(function ($) {

$(document).ready(function() {

	$("#site-header .block-menu-block").css('display', 'block');

	$('.i18n-en .block-search .form-item-search-block-form input.form-text').attr("placeholder", "Search").val('');
	$('.i18n-fr .block-search .form-item-search-block-form input.form-text').attr("placeholder", "Chercher").val('');
	$('.i18n-pt .block-search .form-item-search-block-form input.form-text').attr("placeholder", "Buscar").val('');

	$('.menu-name-main-menu.menu-block-1 > ul.menu').addClass('sm').addClass('sm-simple').smartmenus({
		subMenusMinWidth:'12em',
		mainMenuSubOffsetX:0,
		mainMenuSubOffsetY:0,
		subMenusSubOffsetX:0,
		subMenusSubOffsetY:0,
		markCurrentItem:true
	});

	if ($('.view .view-header').length) {
		$('.view .view-header').appendTo('.view .view-filters');
	}

	$(function() {
		var $mainMenuState = $('#main-menu-state');
		if ($mainMenuState.length) {
			// animate mobile menu
			$mainMenuState.change(function(e) {
				var $menu = $('#main-menu');
				if (this.checked) {
					$menu.hide().slideDown(250, function() { $menu.css('display', ''); });
				} else {
					$menu.show().slideUp(250, function() { $menu.css('display', ''); });
				}
			});
			// hide mobile menu beforeunload
			$(window).bind('beforeunload unload', function() {
				if ($mainMenuState[0].checked) {
					$mainMenuState[0].click();
				}
			});
		}
	});

	$('.card-full-width-image').closest('.section-row').addClass('section-full-width-image');
	
	$('.node-cover-image').closest('.detail-content-wrapper').addClass('has-cover-image');
	
	$('.view-links').closest('.section-row').addClass('match-heights');

	$('.view-promo').closest('.section-row, .side-row').addClass('section-with-promo');
	
	$('.card-body-landing-block').closest('.section-row').addClass('has-landing-block');
	
	$('.card-body-landing-block').siblings('.card-original').closest('.section-row').addClass('has-landing-block-img');
	
	$('.view-slider').closest('.section-row, .side-row').addClass('section-with-slider');
	$('.view-slider').each(function() {
		$('.view-content', this).slick({
			fade:true,
			dots:true,
			infinite:true,
			speed:300,
			slidesToShow:1,
			adaptiveHeight:false,
			appendArrows:$('.slick-nav', this),
			appendDots:$('.slick-nav', this),
			responsive: [
				{
					breakpoint:480,
					settings: {
						fade:false
					}
				}
			]
		});
	});

	$('.slick-nav').closest('.view-footer').addClass('slick-nav-wrapper');
	$('.slick-controls').closest('.view-footer').addClass('slick-controls-wrapper');
	
	// credits expanded
	$('.credit').click(function() {
		$(this).toggleClass("collapsed");
	});
	$('.credit').closest('.card-image-et').addClass('credit-wrapper');
	
	$('.view-display-id-attachment.diamond-list').find('.view-content').addClass('diamond-header');
	
	$('.popup-Tanzanite').closest('.leaflet-popup-content-wrapper').css('border-bottom', 'solid 3px red');

	$('.section-row .card-photos').closest('.section-row').addClass('section-with-gallery');
	$('.side-row .card-photos').closest('.side-row').addClass('section-with-gallery');

	$('.card-photos').each(function() {
		$('ul', this).slick({
			dots:true,
			infinite:false,
			speed:300,
			slidesToShow:1,
			centerMode:true,
			variableWidth:true,
			appendArrows:$(this).closest('.view-content').next('.view-footer').find('.slick-controls'),
			appendDots:$(this).closest('.view-content').next('.view-footer').find('.slick-controls')
		});
	});

	$('.view-type-slider .section-inner .view').append('<div class="view-footer"><div class="slick-controls"></div></div>');
  
  $('.view-type-slider .view-alerts').append('<div class="view-footer view-footer-controls"><div class="slick-controls"></div></div>');
  
  $('.view-type-slider .view-alerts .card-feed-link').appendTo('.view-type-slider .view-alerts .view-footer-controls');
  
	$('.row-max-6.view-type-slider').each(function() {
		$('.view .view-content', this).slick({
			dots:true,
			infinite:true,
			speed:300,
			slidesToShow:6,
			slidesToScroll:6,
			appendArrows:$('.slick-controls', this),
			appendDots:$('.slick-controls', this),
			responsive: [
				{
					breakpoint:960,
					settings: {
						slidesToShow:6,
						slidesToScroll:6,
						dots:true,
						infinite:true
					}
				},
				{
					breakpoint:720,
					settings:{
						slidesToShow:3,
						slidesToScroll:3,
						dots:true,
						infinite:true
					}
				},
				{
					breakpoint:480,
					settings: {
						slidesToShow:1,
						slidesToScroll:1,
						dots:true,
						infinite:false,
						adaptiveHeight:true
					}
				}
			]
		});
	});
	$('.row-max-4.view-type-slider').each(function() {
		$('.view .view-content', this).slick({
			dots:true,
			infinite:true,
			speed:300,
			slidesToShow:4,
			slidesToScroll:4,
			appendArrows:$('.slick-controls', this),
			appendDots:$('.slick-controls', this),
			responsive: [
				{
					breakpoint:960,
					settings: {
						slidesToShow:3,
						slidesToScroll:3,
						dots:true,
						infinite:true
					}
				},
				{
					breakpoint:720,
					settings:{
						slidesToShow:3,
						slidesToScroll:3,
						dots:true,
						infinite:true
					}
				},
				{
					breakpoint:480,
					settings: {
						slidesToShow:1,
						slidesToScroll:1,
						dots:true,
						infinite:false,
						adaptiveHeight:true
					}
				}
			]
		});
	});
	$('.row-max-3.view-type-slider').each(function() {
		$('.view .view-content', this).slick({
			dots:true,
			infinite:true,
			speed:300,
			slidesToShow:3,
			slidesToScroll:3,
			appendArrows:$('.slick-controls', this),
			appendDots:$('.slick-controls', this),
			responsive: [
				{
					breakpoint:960,
					settings: {
						slidesToShow:3,
						slidesToScroll:3,
						dots:true,
						infinite:true
					}
				},
				{
					breakpoint:720,
					settings:{
						slidesToShow:2,
						slidesToScroll:2,
						dots:true,
						infinite:true
					}
				},
				{
					breakpoint:480,
					settings: {
						slidesToShow:1,
						slidesToScroll:1,
						dots:true,
						infinite:false,
						adaptiveHeight:true
					}
				}
			]
		});
	});
	$('.row-max-2.view-type-slider').each(function() {
		$('.view .view-content', this).slick({
			dots:true,
			infinite:true,
			speed:300,
			slidesToShow:2,
			slidesToScroll:2,
			appendArrows:$('.slick-controls', this),
			appendDots:$('.slick-controls', this),
			responsive: [
				{
					breakpoint:960,
					settings: {
						slidesToShow:2,
						slidesToScroll:2,
						dots:true,
						infinite:true
					}
				},
				{
					breakpoint:720,
					settings:{
						slidesToShow:1,
						slidesToScroll:1,
						dots:true,
						infinite:true
					}
				},
				{
					breakpoint:480,
					settings: {
						slidesToShow:1,
						slidesToScroll:1,
						dots:true,
						infinite:false,
						adaptiveHeight:true
					}
				}
			]
		});
	});
	

	$('.view-twitter:not(.side-row .view-twitter)').each(function() {
		$('.view-content', this).slick({
			dots:true,
			infinite:true,
			speed:300,
			slidesToShow:4,
			slidesToScroll:4,
			appendArrows:$('.slick-controls', this),
			appendDots:$('.slick-controls', this),
			adaptiveHeight:true,
			responsive: [
				{
					breakpoint:960,
					settings: {
						slidesToShow:3,
						slidesToScroll:3,
						dots:true,
						infinite:true
					}
				},
				{
					breakpoint:720,
					settings:{
						slidesToShow:2,
						slidesToScroll:2,
						dots:true,
						infinite:true
					}
				},
				{
					breakpoint:480,
					settings: {
						slidesToShow:1,
						slidesToScroll:1,
						dots:false,
						infinite:false
					}
				}
			]
		});
	});
	
	$('.side-row .view-twitter').each(function() {
		$('.view-content', this).slick({
			dots:true,
			infinite:true,
			speed:300,
			slidesToShow:1,
			slidesToScroll:1,
			appendArrows:$('.slick-controls', this),
			appendDots:$('.slick-controls', this),
			adaptiveHeight:true,
			responsive: [
				{
					breakpoint:960,
					settings: {
						slidesToShow:1,
						slidesToScroll:1,
						dots:true,
						infinite:true
					}
				},
				{
					breakpoint:720,
					settings:{
						slidesToShow:1,
						slidesToScroll:1,
						dots:true,
						infinite:true
					}
				},
				{
					breakpoint:480,
					settings: {
						slidesToShow:1,
						slidesToScroll:1,
						dots:false,
						infinite:false
					}
				}
			]
		});
	});

  $('.side-row.view-type-slider').each(function() {
    $('.view-content', this).slick({
      fade:true,
      dots:true,
      infinite:true,
      speed:300,
      slidesToShow:1,
      adaptiveHeight:false,
      appendArrows:$('.slick-controls', this),
      appendDots:$('.slick-controls', this),
    });
  });

  $('.sliderfy').each(function() {
  	$('.view-content', this).slick({
  		dots:true,
  		infinite:true,
  		speed:300,
  		slidesToShow:4,
  		slidesToScroll:4,
  		appendArrows:$('.slick-controls', this),
  		appendDots:$('.slick-controls', this),
  		adaptiveHeight:true,
  		responsive: [
  			{
  				breakpoint:960,
  				settings: {
  					slidesToShow:3,
  					slidesToScroll:3,
  					dots:true,
  					infinite:true
  				}
  			},
  			{
  				breakpoint:720,
  				settings:{
  					slidesToShow:2,
  					slidesToScroll:2,
  					dots:true,
  					infinite:true
  				}
  			},
  			{
  				breakpoint:480,
  				settings: {
  					slidesToShow:1,
  					slidesToScroll:1,
  					dots:false,
  					infinite:false
  				}
  			}
  		]
  	});
  });
	
	$('.sliderfy6').each(function() {
  	$('.view-content', this).slick({
  		dots:true,
  		infinite:true,
  		speed:300,
  		slidesToShow:6,
  		slidesToScroll:6,
  		appendArrows:$('.slick-controls', this),
  		appendDots:$('.slick-controls', this),
  		adaptiveHeight:true,
  		responsive: [
  			{
  				breakpoint:960,
  				settings: {
  					slidesToShow:4,
  					slidesToScroll:4,
  					dots:true,
  					infinite:true
  				}
  			},
  			{
  				breakpoint:720,
  				settings:{
  					slidesToShow:3,
  					slidesToScroll:3,
  					dots:true,
  					infinite:true
  				}
  			},
  			{
  				breakpoint:480,
  				settings: {
  					slidesToShow:1,
  					slidesToScroll:1,
  					dots:false,
  					infinite:false
  				}
  			}
  		]
  	});
  });

  	$('#slickMenu').each(function() {
    	$('#slickMenuContents', this).slick({
    		centre: true,
    		// dots:true,
    		infinite:false,
    		speed:300,
    		// slidesToShow:6,
    		slidesToScroll:2,
    		appendArrows:$(this),
    		appendDots:$(this),
    		adaptiveHeight:false,
    		variableWidth: true,
    		responsive: [
  			{
  				breakpoint:960,
  				settings: {
  					// slidesToShow:4,
  					slidesToScroll:2,
  				}
  			},
  			{
  				breakpoint:720,
  				settings:{
  					// slidesToShow:3,
  					slidesToScroll:2,
  				}
  			},
  			{
  				breakpoint:480,
  				settings: {
  					// slidesToShow:1,
  					slidesToScroll:1,
  				}
  			}
  		]
    	});
    });


	function scaleA4() {
		$('.view-sections img:not(.view-sections .view img)').each(function() {
			$(this).load(function() {
				var ar = $(this).width() / $(this).height();
				if ((ar > 7/10) && (ar < 70/98)) {
					$(this).css({ height:'100%', maxWidth: '420px' });
				}
			});
		});
	}

	scaleA4();

	function createCaptions() {
		$('.view-sections img:not(.view-sections .view img, .view-sections .caption img)').each(function() {
			if ($(this).attr('title')) {
				var caption = $(this).attr('title');
				if ($(this).parent('a').length) {
					$(this).parent('a').wrapAll('<span class="caption"/>').after('<p>'+caption+'</p>');
					$(this).load(function() { var width = $(this).width(); $(this).closest('.caption').find('p').width(width); });
				} else {
					$(this).wrapAll('<span class="caption"/>').after('<p>'+caption+'</p>');
					$(this).load(function() { var width = $(this).width(); $(this).next('p').width(width); });
				}
			}
		});
	}

	createCaptions();

	function updateCaptions() {
		$('.view-sections .caption img').each(function() {
			var width = $(this).width();
			if ($(this).parent('a').length) {
				$(this).closest('.caption').find('p').width(width);
			} else {
				$(this).next('p').width(width);
			}
		});
	}



	/* Feed nugget match heights option */
	function equalHightsTick() {
		$('.card-select-feed').each(function(){
			$(this).siblings('.card-equal-heights').hide(); // move to css when theme is ready
			var isEnabled = $(this).siblings('.card-equal-heights').text().replace(/\s/g,'');
			if (isEnabled == '1') {
				// selectors below have to be checked as the views are different..
				$('.view-content, .view-content ul, .view-content-main > .views-row, .view-content-main .view-content > .views-row',this).addClass('match-heights');
				
			}
		});

	}
	equalHightsTick();

	/* MATCH HEIGHTS SCRIPT*/
	function matchAllHeights() {
		$('.match-img-heights .image-height').matchHeight();
		$('.match-img-heights .card-logo').matchHeight();
		$('.match-card-heights .card-height').matchHeight();
		$('.match-heights .card').matchHeight();
	}
	matchAllHeights();

	var $win = $(window);
	var lastWinWidth = $win.width();
	$win.resize(function(){
		clearTimeout($win.windowResized);
		$win.windowResized = setTimeout(function() {
			var winWidth = $win.width();
			if (lastWinWidth !== winWidth) {
				lastWinWidth = winWidth;
				/* RUN THE FOLLOWING FUNCTIONS ON WINDOW RESIZE */
				equalHightsTick();
				matchAllHeights();
				scaleA4();
				updateCaptions();
				/* ------------------- EOL -------------------- */
			}
		}, 250);
	});



	var $doc = $('html, body');
	$('a[href^="#anchor-"]').click(function() {
		var href = $.attr(this, 'href');
		$doc.animate({
			scrollTop: $(href).offset().top
		}, 500, function () {
			window.location.hash = href;
		});
		return false;
	});



	Drupal.behaviors.eahealth = {
		attach: function (context, settings) {

			if ($('.view .view-header', context).length) {
				$('.view .view-header',context).appendTo('.view .view-filters');
			}

			/*
			$('.view-slider', context).closest('.section-row').addClass('section-with-slider');
			$('.view-slider', context).each(function() {
				$('.view-content', this).slick({
					fade:true,
					dots:true,
					infinite:true,
					speed:300,
					slidesToShow:1,
					adaptiveHeight:false,
					appendArrows:$('.slick-nav', this),
					appendDots:$('.slick-nav', this)
				});
			});

			$('.section-row .card-photos', context).closest('.section-row').addClass('section-with-gallery');
			$('.card-photos', context).each(function() {
				$('ul', this).slick({
					dots:true,
					infinite:false,
					speed:300,
					slidesToShow:1,
					centerMode:true,
					variableWidth:true,
					appendArrows:$('.slick-controls', this),
					appendDots:$('.slick-controls', this)
				});
			});

			$('.view-type-slider .section-inner .view', context).append('<div class="view-footer"><div class="slick-controls"></div></div>');
			$('.row-max-4.view-type-slider', context).each(function() {
				$('.view .view-content', this).slick({
					dots:true,
					infinite:true,
					speed:300,
					slidesToShow:4,
					slidesToScroll:4,
					appendArrows:$('.slick-controls', this),
					appendDots:$('.slick-controls', this),
					responsive: [
						{
							breakpoint:960,
							settings: {
								slidesToShow:3,
								slidesToScroll:3,
								dots:true,
								infinite:true
							}
						},
						{
							breakpoint:720,
							settings:{
								slidesToShow:3,
								slidesToScroll:3,
								dots:true,
								infinite:true
							}
						},
						{
							breakpoint:480,
							settings: {
								slidesToShow:1,
								slidesToScroll:1,
								dots:true,
								infinite:false,
								adaptiveHeight:true
							}
						}
					]
				});
			});
			$('.row-max-3.view-type-slider', context).each(function() {
				$('.view .view-content', this).slick({
					dots:true,
					infinite:true,
					speed:300,
					slidesToShow:3,
					slidesToScroll:3,
					appendArrows:$('.slick-controls', this),
					appendDots:$('.slick-controls', this),
					responsive: [
						{
							breakpoint:960,
							settings: {
								slidesToShow:3,
								slidesToScroll:3,
								dots:true,
								infinite:true
							}
						},
						{
							breakpoint:720,
							settings:{
								slidesToShow:2,
								slidesToScroll:2,
								dots:true,
								infinite:true
							}
						},
						{
							breakpoint:480,
							settings: {
								slidesToShow:1,
								slidesToScroll:1,
								dots:true,
								infinite:false,
								adaptiveHeight:true
							}
						}
					]
				});
			});
			$('.row-max-2.view-type-slider', context).each(function() {
				$('.view .view-content', this).slick({
					dots:true,
					infinite:true,
					speed:300,
					slidesToShow:2,
					slidesToScroll:2,
					appendArrows:$('.slick-controls', this),
					appendDots:$('.slick-controls', this),
					responsive: [
						{
							breakpoint:960,
							settings: {
								slidesToShow:2,
								slidesToScroll:2,
								dots:true,
								infinite:true
							}
						},
						{
							breakpoint:720,
							settings:{
								slidesToShow:1,
								slidesToScroll:1,
								dots:true,
								infinite:true
							}
						},
						{
							breakpoint:480,
							settings: {
								slidesToShow:1,
								slidesToScroll:1,
								dots:true,
								infinite:false,
								adaptiveHeight:true
							}
						}
					]
				});
			});

			$('.view-twitter', context).each(function() {
				$('.view-content', this).slick({
					dots:true,
					infinite:true,
					speed:300,
					slidesToShow:4,
					slidesToScroll:4,
					appendArrows:$('.slick-controls', this),
					appendDots:$('.slick-controls', this),
					adaptiveHeight:true,
					responsive: [
						{
							breakpoint:960,
							settings: {
								slidesToShow:3,
								slidesToScroll:3,
								dots:true,
								infinite:true
							}
						},
						{
							breakpoint:720,
							settings:{
								slidesToShow:2,
								slidesToScroll:2,
								dots:true,
								infinite:true
							}
						},
						{
							breakpoint:480,
							settings: {
								slidesToShow:1,
								slidesToScroll:1,
								dots:false,
								infinite:false
							}
						}
					]
				});
			});
			*/



			$(window).trigger('resize'); //to activate matchHeights

      $('.view-admin-directory select[name="organisation"], select[name="type[]"], select[name="partner_states[]"], select[name="event_type[]"], select[name="location[]"], select[name="resource_type"], .view-id-directory_orgs select[name="excellence[]"], .view-id-directory_people select[name="specialties[]"], .view-id-directory_services select[name="specialised_healthcare[]"], .view-id-directory_products select[name="product_type[]"], .view-id-indicator_list select[name="jump"], .container-header #metric-select select[name="field-metrics-select"], .view-id-directory_products select[name="organisations[]"], .view-id-directory_research select[name="organisations[]"], .view-id-directory_courses select[name="organisations[]"], .view-id-directory_products select[name="organisations[]"], .view-id-directory_opportunity select[name="organisations[]"], .view-id-directory_services select[name="organisations[]"], .view-id-directory_people select[name="organisations[]"], .view-id-events.view-display-id-past select[name="partner_states[]"], .view-id-events.view-display-id-past select[name="event_type[]"], .view-id-events.view-display-id-past select[name="location[]"], .view-id-alerts select[name="disease[]"]').chosen({
				no_results_text:"No results match",
				placeholder_text_multiple:"Type or select",
				placeholder_text_single:"Type or select",
				search_contains:true
			});

		}
	};



	jQuery(document).ajaxSend(function(event, xhr, settings) {
		if ((Drupal.settings.views) && (Drupal.settings.views.ajaxViews)) {
			$('body').addClass('ajax-processing');
		}
		//console.log('Sending...');
	});

	jQuery(document).ajaxComplete(function(event, xhr, settings) {
		if ((Drupal.settings.views) && (Drupal.settings.views.ajaxViews)) {
			$('body').removeClass('ajax-processing');
		}
		//console.log('Complete!');
	});


	
	//  FAQ COLLAPSE/EXPAND
	$('.card-qa-question').click(function(e) {
		e.preventDefault();
		var notthis = $('.active').not(this);
		notthis.toggleClass('active').next('.card-qa-answer').slideToggle(300);
	 	$(this).toggleClass('active').next().slideToggle("fast");
	});
	$('.expand-list .card-qa-question').click(function(e) {
		e.preventDefault();
		var notthis = $('.expand-active').not(this);
		notthis.toggleClass('expand-active').parent().next('.expand-inner').slideToggle(300);
	 	$(this).toggleClass('expand-active').parent().next().slideToggle("fast");
	});

	//$(".view-type-slider .slick-controls:empty").closest('.view-footer').hide(); /* this is incomplete... */

	//Show/hide summary classes 
	$('div.card-text:has(div.card-summary-wrapper)').addClass('card-with-summary');

	// Indicator database metric title
  if ($('#metric-name').length > 0) {
	  window.onload = function getFirstMetric() {
	  	$currentMetric = [];
	    var currentMetric = document.getElementById("field-metrics-select");
	  	$currentMetric = String(currentMetric.options[currentMetric.selectedIndex].text);
	    $('#metric-name').html($currentMetric);
	  };
	  $("#field-metrics-select").on('change', function() {
	    var selectedMetric = document.getElementById("field-metrics-select");
	    $currentMetric = String(selectedMetric.options[selectedMetric.selectedIndex].text);
	    $('#metric-name').html($currentMetric);
	  });
	};

});

})(jQuery);
